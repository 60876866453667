import { render, staticRenderFns } from "./CardHospitalization.vue?vue&type=template&id=036e965c"
import script from "./CardHospitalization.vue?vue&type=script&lang=js"
export * from "./CardHospitalization.vue?vue&type=script&lang=js"
import style0 from "./CardHospitalization.vue?vue&type=style&index=0&id=036e965c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports