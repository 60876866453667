<template>
  <div class="recipe-page">
    <template v-for="(item, i) in items">
      <card-manipulated
        v-if="item.manipulated"
        :key="item.key"
        :medicalRecordId="medicalRecordId"
        :data="item"
        @remove="remove(i)"
      ></card-manipulated>
      <card-default
        v-else
        :medicalRecordId="medicalRecordId"
        :key="item.key"
        :data="item"
        :dosages="dosages"
        @removeDosage="removeDosage"
        @remove="remove(i)"
      />
    </template>

    <div class="recipe-card">
      <div class="columns">
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <button class="btn btn-lg btn-gray" @click="add">
            Novo receituário
          </button>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <button class="btn btn-lg btn-gray" @click="addManipulated">
            Receita manipulada
          </button>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <button
            class="btn btn-lg btn-icon btn-icon-left"
            :class="{
          loading: loadingMemed,
          'btn-gray': !memed.errorMessage,
          'btn-gray-outline': memed.errorMessage
        }"
            :disabled="loadingMemed"
            @click="openMemed"
          >
            <fa-icon
              v-if="memed.errorMessage"
              class="text-warning"
              :icon="['fal', 'exclamation-triangle']"
            />
            <span>Prescrever com Memed</span>
          </button>
        </div>
      </div>
    </div>
    <div class="recipe-previous" v-if="previous.length > 0">
      <h6 class="text-center">Receituários anteriores</h6>
      <div class="previous-item" v-for="(prescription, p) in previous" :key="p">
        <span class="text-bold">
          {{ prescription.date | date }} - {{ prescription.professionalName}}
        </span>
        <div class="mb-2" v-for="(drug, i) in prescription.drugs" :key="i">
          <div>{{ drug.name }} - {{ drug.quantity }} {{ unit.getName(drug.unit) }}</div>
          <small>{{ drug.dosage }}</small>
        </div>
        <div
          v-if="prescription.notes"
          v-html="prescription.notes"
          class="recipe-detail-notes"
        ></div>
        <button class="btn btn-sm btn-info"
                :class="{loading: loadingPrescribe}"
                :disabled="loadingPrescribe"
                @click="prescribeAgain(prescription)">
          Prescrever novamente
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as memed from '@/services/memed';
import * as unit from 'src/data/drug-units';
import { randomHash } from 'src/helpers';
import CardDefault from './card/Card.vue';
import CardManipulated from './card/CardManipulated.vue';

export default {
  components: {
    CardDefault,
    CardManipulated,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    patientId: {
      type: String,
    },
    patient: {
      type: Object,
    },
  },
  data() {
    return {
      unit,
      loadingPrescribe: false,
      loadingMemed: false,
      items: [],
      previous: [],
      dosages: [],
      memed: {
        ready: false,
        errorMessage: memed.getErrorMessage(),
      },
    };
  },
  mounted() {
    this.load(this.medicalRecordId);
    this.loadPrevious();
    this.loadDosages();
  },
  methods: {
    load(id) {
      this.$httpX.get(`/medical-records/${id}/recipes`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(this.$toast.error);
    },
    loadDosages() {
      const params = {
        limit: 500,
      };
      this.$httpX.get('/medicine-dosages', { params })
        .then(({ data }) => {
          this.dosages = data.items;
          this.initMemed();
        })
        .catch(this.$toast.error);
    },
    loadPrevious() {
      this.$httpX.get(`/patients/${this.patientId}/recipes`)
        .then(({ data: items }) => {
          this.previous = items.map((item) => {
            if (item.notes) {
              item.notes = item.notes.trim()
                .replace(/^(<p>&nbsp;<\/p>)+/i, '')
                .replace(/(<p>&nbsp;<\/p>)+$/i, '');
            }
            return item;
          });
        })
        .catch(this.$toast.error);
    },
    prescribeAgain(item) {
      this.loadingPrescribe = true;
      const data = {
        key: randomHash(),
        id: '',
        date: moment().format('YYYY-MM-DD'),
        printDate: true,
        notes: item.notes,
        manipulated: item.manipulated || item.drugs.length === 0,
        drugs: item.drugs.map(drug => ({
          key: randomHash(),
          id: drug.id,
          name: drug.name,
          quantity: drug.quantity,
          unit: drug.unit,
          access: drug.access,
          dosage: drug.dosage,
          specialControl: drug.specialControl || false,
        })),
      };

      this.$httpX
        .post(`/medical-records/${this.medicalRecordId}/recipes`, data)
        .then(({ data: prescription }) => {
          data.id = prescription.id;
          this.items.push(data);
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loadingPrescribe = false;
          this.$emit('scroll-top');
        });
    },
    add() {
      this.items.push(this.formBlank(false));
    },
    addManipulated() {
      this.items.push(this.formBlank(true));
    },
    async initMemed() {
      try {
        await memed.init();
        this.memed.ready = true;
      } catch (error) {
        this.memed.errorMessage = memed.getErrorMessage();
      }
    },
    async onMemedFinished({ prescricao }) {
      prescricao.medicalRecordId = this.medicalRecordId;
      try {
        const res = await this.$http.post('/memed/professionals/0/prescriptions', prescricao);
        this.items.push(res.data);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    onMemedDeleted(id) {
      const idx = this.items.findIndex(item => (
        item.origin === 'memed' && item.originId === id.toString()
      ));
      if (idx >= 0) {
        this.remove(idx);
      }
    },
    async openMemed() {
      if (memed.hasError()) {
        this.$notification.show('Memed', {
          type: 'error',
          content: memed.getErrorMessage(),
          ellipsis: false,
          timeout: 15000,
        });
      }

      if (!this.memed.ready) return;

      this.loadingMemed = true;
      try {
        await memed.showPrescription(this.patient);
        memed.addEvent(memed.PRESCRIPTION_FINISH_EVENT, this.onMemedFinished);
        memed.addEvent(memed.PRESCRIPTION_DELETE_EVENT, this.onMemedDeleted);
      } catch (e) {
        this.$toast.error(e);
      }
      this.loadingMemed = false;
    },
    removeDosage({ id, idx }) {
      this.dosages.splice(idx, 1);
      this.$httpX.delete(`/medicine-dosages/${id}`)
        .catch(this.$toast.error);
    },
    remove(idx) {
      const found = this.items[idx];
      if (!found.id) {
        this.items.splice(idx, 1);
        return;
      }

      this.$httpX.delete(`/medical-records/${this.medicalRecordId}/recipes/${found.id}`)
        .then(() => {
          this.items.splice(idx, 1);
        })
        .catch(this.$toast.error);
    },
    formBlank(manipulated = false) {
      return {
        key: randomHash(),
        id: '',
        notes: '',
        date: moment().format('YYYY-MM-DD'),
        drugs: [],
        origin: null,
        originId: null,
        manipulated,
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .recipe-page {
    .recipe-card {
      background-color: lighten($gray-color, 24%);
      border: $border-width dotted $gray-color;
      border-radius: $border-radius;
      padding: 1.5rem 0;
      text-align: center;
      button {
        width: 12rem;
      }
    }
    .recipe-previous {
      margin-top: $layout-spacing-lg;
      .previous-item {
        border: $border-color solid $border-width;
        border-radius: $border-radius;
        margin: $layout-spacing-lg 0;
        padding: $layout-spacing-lg;
      }
      .recipe-detail-notes {
        margin: $layout-spacing 0;
        white-space: pre-wrap;
      }
    }
  }
</style>
